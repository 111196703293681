<template>
  <m-basic-form
    @save="save"
    @remove="remove"
    :loadingMsg="loadingMsg"
    :loadingShow="loadingShow"
    :formId="ID"
  >
    <div class="row">
      <div class="col-5">
        <m-input v-model="form.BOXCODE" langlabel="boxcode" />
        <m-input v-model="form.BOXNAME" langlabel="boxname" />
        <m-input
          v-model="form.BOXPRICE"
          @keyup.native="calcAllPrice"
          type="number"
          langlabel="pricenontax"
        />

        <m-input
          v-model="form.TAXRATE"
          @keyup.native="calcAllPrice"
          type="number"
          langlabel="taxrate"
        />
        <m-input
          v-model="form.TAXAMOUNT"
          readonly
          type="number"
          langlabel="taxamount"
        />
        <m-input
          v-model="form.BOXAMOUNT"
          readonly
          type="number"
          langlabel="amount"
        />
        <m-yes-no v-model="form.ISACTIVE" langlabel="isactive" />
      </div>
    </div>
  </m-basic-form>
</template>

<script>
import api from "../../api";
import swal from "sweetalert";
export default {
  mounted() {
    if (intval(this.$route.params.id) > 0) {
      this.ID = intval(this.$route.params.id);
      this.getBox(this.ID);
    }
  },
  data() {
    return {
      ID: null,
      desi: [],
      form: {
        BOXCODE: null,
        BOXNAME: null,
        BOXPRICE: null,
        TAXRATE: null,
        TAXAMOUNT: null,
        BOXAMOUNT: null,
        ISACTIVE: 1,
      },
      loadingShow: false,
      loadingMsg: null,
    };
  },
  methods: {
    calcAllPrice() {
      if (
        toNumberFromTr(this.form.BOXPRICE) &&
        toNumberFromTr(this.form.TAXRATE)
      ) {
        this.form.TAXAMOUNT = toTrFromNumber(
          (toNumberFromTr(this.form.BOXPRICE) *
            toNumberFromTr(this.form.TAXRATE)) /
            100
        );
        this.form.BOXAMOUNT = toTrFromNumber(
          toNumberFromTr(this.form.TAXAMOUNT) +
            toNumberFromTr(this.form.BOXPRICE)
        );
      }
    },
    save() {
      this.loadingMsg = this.$t("dialog.savingrecord");
      this.loadingShow = true;
      api
        .saveBox(
          {
            ...this.form,
            BOXPRICE: toNumberFromTr(this.form.BOXPRICE),
            TAXRATE: toNumberFromTr(this.form.TAXRATE),
            TAXAMOUNT: toNumberFromTr(this.form.TAXAMOUNT),
            BOXAMOUNT: toNumberFromTr(this.form.BOXAMOUNT),
          },

          this.ID
        )
        .then((res) => {
          if (res.data.status) {
            if (this.ID == res.data.data.ID) {
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullyupdatedrecord"),
                "success"
              );
            } else {
              this.ID = res.data.data.ID;
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullysaverecord"),
                "success"
              );
            }
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    getBox(id) {
      this.loadingMsg = this.$t("dialog.fetchingdata");
      this.loadingShow = true;
      api
        .getBoxOne(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            const {
              BOXCODE,
              BOXNAME,
              BOXPRICE,
              TAXRATE,
              TAXAMOUNT,
              BOXAMOUNT,
              ISACTIVE,
            } = res.data.data;
            this.form = {
              BOXCODE,
              BOXNAME,
              BOXPRICE: toTrFromNumber(BOXPRICE),
              TAXRATE: toTrFromNumber(TAXRATE),
              TAXAMOUNT: toTrFromNumber(TAXAMOUNT),
              BOXAMOUNT: toTrFromNumber(BOXAMOUNT),
              ISACTIVE,
            };
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    remove() {
      this.loadingMsg = this.$t("dialog.removingrecord");
      this.loadingShow = true;
      api
        .deleteBox(this.ID)
        .then((res) => {
          if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
  },
};
</script>

<style></style>
